import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";


function OurVideos() {
    const { rpdata } = useContext(GlobalDataContext);

    // const [open, setOpen] = useState(false);
    // const handelOpen = () => {
    //     setOpen(!open)
    // }

    return (
        <BaseLayout PageName="Our Videos">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Our Videos"
                    Subheader="Our Videos"
                    bgimg={`${rpdata?.gallery?.[8]}`}
                />
            </div>
            <div className="w-4/5 mx-auto py-[100px]">
                <h1 className="text-center pb-10">Our Videos</h1>
                <div className="grid grid-cols-1">
                    <iframe
                        title="Promotional video"
                        className='mx-auto w-[100%] h-[400px] rounded-3xl relative top-[10px]'
                        src={`${rpdata?.videosPromo?.[0].link}`}
                        width="640" height="360"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                    />


                </div>
            </div>
        </BaseLayout>
    );
}

export default OurVideos;